@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@200;400&display=swap");

body {
  margin: 0;
  font-family:
    "Noto Sans TC",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: min(2vh, 5vw);
}

::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #8888;
  transition: background-color 0.5s;
  border-radius: 0.175rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}