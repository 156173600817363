#resultPage {
    .copy {
        position: absolute;
        height: 8%;
        width: 45%;
        top: 78%;
        left: 3%;
    }
    .fb {
        position: absolute;
        height: 6%;
        width: 23%;
        top: 80%;
        left: 50%;
    }
    .ig {
        position: absolute;
        height: 6%;
        width: 24%;
        top: 80%;
        left: 74%;
    }
    .fbt {
        position: absolute;
        height: 7%;
        width: 45%;
        top: 88%;
        left: 29%;
    }
}
#resultPage[data-show=false] {
    pointer-events: none;
}

#resultPage[data-show=true] {
    pointer-events: initial;

    img {
        pointer-events: inherit !important;
    }
}