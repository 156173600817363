#loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;

    .text {
        height: 5rem;
        width: 100%;
        text-align: center;
        line-height: 2rem;
        font-size: 1.5rem;
        animation: opac 3s infinite;
        color: #FFF;
        position: relative;
        overflow: hidden;
    }
    .text::before,
    .text::after {
        content: "";
        position: absolute;
        transition: left 0.5s, right 0.5s;
        top: 50%;
        width: 50%;
        height: 0.2rem;
        background-color: #FFF;
    }
    .text::before {
        left: calc(-50% + 50% * var(--progress));
    }
    .text::after {
        right: calc(-50% + 50% * var(--progress));
    }
}

@keyframes opac {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}