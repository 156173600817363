#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .content {
    position: relative;
    height: min(100vh, 1600vw / 9);
    width: min(100vw, 900vh / 16);
    margin: auto;

    #loading {
      opacity: 0%;
      transition-property: opacity;
      transition-duration: 0.8s;
      transition-delay: 0.5s;
    }

    .page {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      isolation: isolate;
      user-select: none;
      transition-property: opacity;
      transition-duration: 1s;
      transition-delay: 1s;

      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: contain;
        height: 100%;
        width: 100%;
        opacity: 0%;
        pointer-events: none;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-delay: 0.5s;
        z-index: calc(-6 + var(--order));
      }

      img[data-show=true] {
        transition-delay: 0s;
        opacity: 100%;
      }

      button {
        background-color: transparent;
        user-select: none;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }

    .page[data-show=false] {
      pointer-events: none;
    }
  }

  .content[data-loaded=false] {
    #loading {
      transition-delay: 1s;
      opacity: 100%;
    }

    .page {
      pointer-events: none;
      opacity: 0%;
    }
  }
}

#app::before {
  content: "";
  position: absolute;
  --w: 142vmax;
  height: var(--w);
  width: var(--w);
  top: calc(50vh - var(--w) / 2);
  left: calc(50vw - var(--w) / 2);
  z-index: -1;
  // 極度耗能
  // animation: rotate 30s linear infinite;
  background-image: linear-gradient(45deg, #009ebe 20%, #0a60cd 40%, #81c8c4 75%);
  ;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}