#questionPage {
    .btn {
        position: absolute;
        left: 12.9%;
        height: 6.2%;
        width: 74.2%;
    }
    .opt1 {
        top: 60%;
    }
    .opt2 {
        top: 73.5%;
    }
}